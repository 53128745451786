import { Atom, Cmp, SHARED_UTILS } from ":mods";
import { TopViewProps } from "../model";
import { For, splitProps } from "solid-js";
import "../style/nav-top.css";
// import { actions } from "../../routes/store";
// import { actions } from "../store";

export function Top(props: TopViewProps) {
  const [local, others] = splitProps(props, ["logoHref", "items", "base", "events", "logoClass"]);
  return (
    <nav {...others}>
      {/* start */}
      <Atom.Route.Anchor
        to={{ base: local.logoHref ?? "/" }}
        onClick={() => {
          local.events?.onLogoClicked(local.logoHref);
        }}
        class={`logo ${local.logoClass ?? ""}`}
        icon="icon-local:logo !w-full !h-full"
      />
      {/* navs */}
      <div class="anchor-group">
        <For each={local.items}>
          {(item) => {
            if (typeof item === "function") {
              return (
                <>
                  {item({
                    actions: {
                      ...Cmp.Alert.actions,
                      ...Cmp.Popup.actions,
                      ...Atom.Route.actions,
                    },
                    base: local.base,
                    classes: { anchor: "anchor" },
                  })}
                </>
              );
            }
            const to = SHARED_UTILS.mergeRouteHref({ base: local.base }, item.to);
            // console.log("anchor group :: ", to);
            return <Atom.Route.Anchor {...item} to={to} class={`anchor ${item.class ?? ""} `} activeClass="active" />;
          }}
        </For>
      </div>
    </nav>
  );
}
